<h2 class="mb-3">Select services</h2>

<app-spinner *ngIf="loading"></app-spinner>

<div class="services" *ngIf="!loading">

  <div class="accordion" id="servicesAccordian">

    <div class="accordion-item" *ngFor="let category of serviceCategories(); let i = index">

      <div class="accordian-header" id="category{{i}}">
        <button class="accordion-button" [ngClass]="{'collapsed': i > 0}" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" aria-controls="collapseOne">
          <div class="w-100 d-flex flex-row me-3">
            <div class="fw-bold fs-5">{{category}}</div>
            <div class="ms-auto" *ngIf="categoryHasSelectedServices(category)">
              <span class="badge rounded-pill bg-secondary">{{ getSelectedServiceCountForCategory(category) }}</span>
            </div>
          </div>
        </button>
      </div>

      <div id="collapse{{i}}" class="accordion-collapse collapse" [ngClass]="{'show': i === 0}" [attr.aria-labelledby]="'#heading' + i" data-bs-parent="#servicesAccordian">

        <div class="accordion-body p-4">

          <div *ngFor="let service of servicesForCategory(category)" class="service">

            <div class="form-check form-check-lg d-flex gap-3">

              <div>
                <input type="checkbox"
                    id="{{service.isPackage ? 'package' : 'service'}}{{service.itemRef}}"
                    class="form-check-input form-check-input-rounded"
                    (change)="onServiceCheckChange($event, service)"
                    [checked]="isSelected(service)">
              </div>

              <label for="{{service.isPackage ? 'package' : 'service'}}{{service.itemRef}}" class="flex-grow-1">
                <div class="d-flex">
                  <div>
                    <div class="fw-semibold">{{service.itemName}}</div>
                    <div class="text-muted">{{(service.bookingDurationMinutes + service.bookingGapMinutes) | duration}}</div>
                  </div>

                  <div *ngIf="(business$ | async)?.showPrices" class="ms-auto">{{service.grossSalePrice | currency}}</div>
                </div>
              </label>

            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

  <div class="d-grid gap-2 d-md-block mt-3">
    <button class="btn btn-primary" type="button" (click)="onContinue()" [disabled]="!(isValid$ | async)">Continue</button>
    <button class="btn btn-outline-secondary ms-md-2" type="button" *ngIf="canGoBack" (click)="appService.goBack()">Back</button>
  </div>

</div>
