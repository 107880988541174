<h2 class="mb-3">Select {{ (business$ | async)?.staffTaxonomySingular | lowercase }}</h2>

<app-spinner *ngIf="loading"></app-spinner>

<div *ngIf="!loading">

  <div *ngIf="!noStaff && view === 'services'">
    <div class="d-grid gap-4">
      <div *ngFor="let service of selectedServices">

        <app-staff-package *ngIf="service.isPackage"
          [package]="service"
          [services]="getServicesForPackage(service)"
          [bookings]="getBookingsForPackage(service)"
          (changeBooking)="onBookingChange($event)">
        </app-staff-package>

        <app-staff-service *ngIf="!service.isPackage"
            [booking]="getBooking(service.itemRef)"
            [service]="service"
            [availableStaff]="getAvailableStaffForService(service.itemRef)"
            (changeBooking)="onBookingChange($event)">
        </app-staff-service>

      </div><!-- /for -->
    </div>

    <div class="d-grid gap-2 d-md-block mt-3">
      <button class="btn btn-primary" type="button" (click)="onContinue()" [disabled]="!(isValid$ | async)">
        Continue
      </button>

      <button class="btn btn-outline-secondary ms-md-2" type="button" (click)="appService.goBack()">
        Back
      </button>
    </div>

  </div><!-- /if -->

  <!--begin::StaffList-->
  <div *ngIf="!noStaff && view === 'staff'" class="card">
    <h5 class="card-header">{{editService!.itemName}}</h5>

    <div class="list-group list-group-flush">

      <button class="list-group-item list-group-item-action p-4"
              [ngClass]="{'active': !editBooking!.staffPreferred}"
              (click)="onSetBookingNoPreference()">
        <div class="d-flex align-items-center">
          <div class="fw-bold me-auto">No Preference</div>
          <i class="fs-4 bi bi-chevron-right"></i>
        </div>
      </button>

      <button *ngFor="let staff of getAvailableStaffForService(editService!.itemRef)"
              class="list-group-item list-group-item-action p-4"
              [ngClass]="{'active': editBooking!.staffRef === staff.staffRef && editBooking!.staffPreferred}"
              (click)="onSetBookingStaff(staff)">
        <div class="d-flex align-items-center">
          <div class="fw-bold me-auto">{{staff.fullName}}</div>
          <i class="fs-4 bi bi-chevron-right"></i>
        </div>
      </button>

    </div>
  </div>
  <!--end::StaffList-->

  <!--begin::NoStaff-->
  <div *ngIf="noStaff">
    <div class="alert alert-danger">
      <p>There are no {{ (business$ | async)?.staffTaxonomyPlural }} available.</p>
      <p class="mb-0" *ngIf="branch$ | async as branch">Please call us on <a href="tel:{{branch.phoneNumber}}">{{branch.phoneNumber}}</a> to see if we can schedule your appointment.</p>
    </div>

    <div class="d-grid gap-2 d-md-block mt-3">
      <button class="btn btn-outline-secondary" type="button" (click)="appService.goBack()">
        Back
      </button>
    </div>
  </div>
  <!--end::NoStaff-->

</div>
