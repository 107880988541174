import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBooking, IBookingRequest } from '../bookings.module';

@Injectable({ providedIn: 'root' })
export class BookingsService {

  constructor(
    private _http: HttpClient
  ) { }

  private mapBooking(data: any): IBooking {
    const booking: IBooking = {} as IBooking;
    Object.assign(booking, data);
    booking.finishDateTime = DateTime.fromISO(data.finishDateTime);
    booking.startDateTime = DateTime.fromISO(data.startDateTime);
    return booking;
  }

  private mapBookings(bookings: IBooking[]): IBooking[] {
    return bookings.map(booking => this.mapBooking(booking));
   }

  public forCustomer(): Observable<IBooking[]> {
    return this._http.get<IBooking[]>(`bookings`)
      .pipe(map(bookings => this.mapBookings(bookings)));
  }

  public submitBooking(request: IBookingRequest): Observable<number> {
    return this._http.post<number>(`bookings`, request);
  }
}
