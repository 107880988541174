import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IStaff } from '../models';

@Injectable({ providedIn: 'root' })
export class StaffService {

  constructor(
    private _http: HttpClient,
  ) {}

  private readonly _availableStaff$ = new BehaviorSubject<{ [itemRef: number]: number[] }>({});
  private readonly _rosteredStaff$ = new BehaviorSubject<IStaff[]>([]);

  public readonly availableStaff$ = this._availableStaff$.asObservable();
  public readonly rosteredStaff$ = this._rosteredStaff$.asObservable();

  public findByRef(staffRef: number): IStaff {
    return this._rosteredStaff$.getValue().find(s => s.staffRef === staffRef)!;
  }

  public getStaffForService(itemRef: number): IStaff[] {
    return this._availableStaff$
      .getValue()[itemRef]
      .map(staffRef => this._rosteredStaff$
        .getValue()
        .find(s => s.staffRef === staffRef)!
      );
  }

  public getStaffForServices(services: number[]): Observable<{ [itemRef: number]: number[] }> {
    const q = services
      .map(i => `itemRef=${i}`)
      .filter((v, i, a) => a.indexOf(v) === i)
      .join('&');

    return this._http.get<{ [itemRef: number]: number[] }>(`staff/services?${q}`)
      .pipe(
        tap(staff => this._availableStaff$.next(staff))
      );
  }

  public getRosteredStaff(): Observable<IStaff[]> {
    return this._http.get<IStaff[]>('staff/rostered')
      .pipe(
        tap(staff => this._rosteredStaff$.next(staff))
      );
  }

}
