import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { BusinessService } from 'src/app/modules/businesses/business.module';
import { IService, ServiceService } from '../../services.module';
import { BranchService } from 'src/app/modules/branches/branch.module';

@Component({
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit, OnDestroy {

  constructor(
    public appService: AppService,
    private _branchService: BranchService,
    private _businessService: BusinessService,
    private _logger: NGXLogger,
    private _serviceService: ServiceService,
  ) { }

  private _selected: IService[] = [];
  private _services: IService[] = [];
  private readonly _destroy$ = new Subject();
  private readonly _isValid$ = new BehaviorSubject<boolean>(false);

  public canGoBack = false;
  public loading = true;
  public readonly business$ = this._businessService.business$;
  public readonly isValid$ = this._isValid$.asObservable();

  ngOnInit(): void {
    this.appService.setStep('services');

    this._branchService.branches$.pipe(take(1)).subscribe(branches => {
      this.canGoBack = branches.length > 1;
    });

    this._serviceService.branchServicesAndPackages$
      .pipe(take(1))
      .subscribe(services => {
        this._logger.trace('services', services);
        this._services = services;
        this.loading = false;
      });


    this.appService.selectedServicesAndPackages$
      .pipe(
          takeUntil(this._destroy$),
          delay(0),
        )
      .subscribe(selected => {
        this._selected = selected;
        this._isValid$.next(selected.length > 0);
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  categoryHasSelectedServices(category: string): boolean {
    return this._selected.some(s => s.itemCategory === category);
  }

  getSelectedServiceCountForCategory(category: string): number {
    return this._selected.filter(s => s.itemCategory === category).length;
  }

  isSelected(service: IService): boolean {
    return this._selected.some(s => s.itemRef === service.itemRef && s.isPackage === service.isPackage);
  }

  onContinue() {
    this.appService.goToNextStep();
  }

  onServiceCheckChange(e: Event, service: IService): void {
    this._logger.debug('[ServicesComponent].[onServiceCheckChange]', service);

    const checked = (e.target as HTMLInputElement).checked;

    if (checked) {
      this.appService.addService(service);
    } else {
      this.appService.removeService(service);
    }
  }

  servicesForCategory(category: string): IService[] {
    return this._services
      .filter(s => s.canBeBookedOnline)
      .filter(s => s.itemCategory === category)
      .sort((a, b) => {
        if (a.itemName > b.itemName) { return 1; }
        if (a.itemName < b.itemName) { return - 1; }
        return 0;
      });
  }

  serviceCategories(): string[] {
    const servicesWithItems = this._services.filter(s => s.canBeBookedOnline);

    return [...new Set(servicesWithItems.map(s => s.itemCategory))]
      .sort((a, b) => {
        if (b === 'Packages') { return 1; }
        if (a === 'Packages') { return -1; }

        if (a > b) { return 1; }
        if (a < b) { return - 1; }

        return 0;
      });
  }

}
