import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBranch } from '../../branches/branch.module';
import { IService } from '../models';

@Injectable({ providedIn: 'root' })
export class ServiceService {

  constructor(
    private _http: HttpClient
  ) { }

  private _branchServicesAndPackages$ = new BehaviorSubject<IService[]>([]);

  public readonly branchServicesAndPackages$ = this._branchServicesAndPackages$.asObservable();

  public findByRef(itemRef: number): IService {
    return this._branchServicesAndPackages$
      .getValue()
      .find(s => !s.isPackage && s.itemRef === itemRef)!
  }

  public getServicesAndPackages(branch: IBranch): Observable<IService[]> {
    return this._http.get<IService[]>(`services/branch/${branch.id}`)
      .pipe(
        tap(services => this._branchServicesAndPackages$.next(services))
      );
  }

}
